import { injectable } from '@/inversify';

@injectable()
export default class JiraService {
    private widget: HTMLElement | null = null;

    async initWidget() {
        await this.loadScript();
    }

    toggleChat() {
        if (!this.widget) {
            return;
        }

        (this.widget.shadowRoot!.querySelector('.chat-icon-button')! as HTMLButtonElement).click();
    }

    get knowledgeBaseLink() {
        // [TODO] move config to env when dev/prod will be splited
        return 'https://fornova-support.atlassian.net/servicedesk/customer/portals ';
    }

    get contactUsFormLink() {
        // [TODO] move config to env when dev/prod will be splited
        return 'https://fornova-support.atlassian.net/servicedesk/customer/portal/2';
    }

    private loadScript() {
        // [TODO] move config to env when dev/prod will be splited
        const scriptElement = document.createElement('script');
        scriptElement.id = 'jsm-snippet';
        scriptElement.async = true;
        scriptElement.src = 'https://chat-api.spartez-software.com/chat-widget.js';
        const widgetElement = document.createElement('chat-widget');
        widgetElement.id = 'jsm-widget';
        widgetElement.setAttribute('jira-id', 'bcd4f201-4696-31db-867a-9e56f545489a');
        widgetElement.setAttribute('service-desk-id', '2');
        widgetElement.setAttribute('auto-show', 'true');

        this.widget = widgetElement;

        this.hideWidget();

        document.head.appendChild(scriptElement);
        document.body.appendChild(widgetElement);

        return new Promise<void>(resolve => {
            widgetElement.addEventListener('load', () => {
                // We can't directly controll widget, need timeouts to wait til it mounted and close animation completes
                setTimeout(() => {
                    this.initWidgetStyles();
                    // this.toggleChat();
                    setTimeout(this.showWidget.bind(this), 500);
                });

                resolve();
            });
        });
    }

    private initWidgetStyles() {
        if (!this.widget) {
            return;
        }

        const wrapper = this.widget.shadowRoot!.querySelector('.chat-widget-wrapper') as HTMLElement;

        wrapper.style.bottom = '-70px';
        wrapper.style.right = '70px';
    }

    private hideWidget() {
        if (!this.widget) {
            return;
        }

        this.widget.style.opacity = '0';
        this.widget.style.pointerEvents = 'none';
        this.widget.style.zIndex = '-1';
    }

    private showWidget() {
        if (!this.widget) {
            return;
        }

        if (!this.widget) {
            return;
        }

        this.widget.style.opacity = '1';
        this.widget.style.pointerEvents = 'all';
        this.widget.style.zIndex = '999999';
    }
}
