import { container } from '@/inversify';
import { AsyncContainerModule } from 'inversify';
import { KEY } from '@/inversify.keys';

import StorageService, { StorageServiceS } from '@/modules/common/services/storage.service';
import EventsApiService, { EventsApiServiceS } from '@/modules/events/events-api.service';
import EventsFilterService, { EventsFilterServiceS } from '@/modules/events/events-filter.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import MarketsCommonService, { MarketsCommonServiceS } from '@/modules/common/modules/markets/markets-common.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import RatesService from '@/modules/rates/rates.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import RatesAllService, { RatesAllServiceS } from '@/modules/rates/rates-all.service';
import DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import InsightFiltersService from '@/modules/document-filters/insight-filters.service';
import RatesApiService, { RatesApiServiceS } from '@/modules/rates/rates-api.service';
import UserApiService, { UserApiServiceS } from '@/modules/user/user-api.service';
import NotifyService, { NotifyServiceS } from '@/modules/common/services/notify.service';
import CustomNotificationService, { CustomNotificationServiceS } from '@/modules/common/modules/custom-notification/custom-notification.service';
import CustomNotificationApiService, { CustomNotificationApiServiceS }
    from '@/modules/common/modules/custom-notification/custom-notification-api.service';
import TokenSessionStorageService, { TokenSessionStorageServiceS } from '@/modules/auth/token-session-storage.service';
import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import UserNavigationService, { UserNavigationServiceS } from '@/modules/user/user-navigation.service';
import UserViewService, { UserViewServiceS } from '@/modules/user/user-view.service';
import ConfigService, { ConfigServiceS } from '@/modules/config/config.service';
import JiraService from '@/modules/jira/jira.service';

import RatesFiltersMiddleware, { RatesFiltersMiddlewareS } from '@/router/middlewares/rates-filters.middleware';
import MarketsFiltersMiddleware, { MarketsFiltersMiddlewareS } from '@/router/middlewares/markets-filters.middleware';
import HomepageRedirectMiddleware, { HomepageRedirectMiddlewareS } from '@/router/middlewares/homepage-redirect.middleware';
import AuthMiddleware, { AuthMiddlewareS } from '@/router/middlewares/auth.middleware';
import LevelMiddleware, { LevelMiddlewareS } from '@/router/middlewares/level.middleware';
import OnboardingMiddleware, { OnboardingMiddlewareS } from '@/router/middlewares/onboarding.middleware';
import PaginationMiddleware, { PaginationMiddlewareS } from '@/router/middlewares/pagination.middleware';
import ViewAsMiddleware, { ViewAsMiddlewareS } from '@/router/middlewares/view-as.middleware';

import ViewAsChainGuard, { ViewAsChainGuardS } from '@/router/guards/view-as-chain.guard';
import ViewAsHotelGuard, { ViewAsHotelGuardS } from '@/router/guards/view-as-hotel.guard';
import CarGuard, { CarGuardS } from '@/router/guards/car.guard';
import HotelGuard, { HotelGuardS } from '@/router/guards/hotel.guard';
import ClusterOrChainGuard, { ClusterOrChainGuardS } from '@/router/guards/cluster-or-chaine.guard';
import HotelOrCarGuard, { HotelOrCarGuardS } from '@/router/guards/hotel-or-car.guard';
import ClusterGuard, { ClusterGuardS } from '@/router/guards/cluster.guard';
import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';

import HomeService from '@/modules/home/home.service';
import CompsetSettingsService, { CompsetSettingsServiceS } from '@/modules/compsets/compset-settings.service';
import MarketsApiService, { MarketsApiServiceS } from '@/modules/markets/markets-api.service';
import MarketsService from '@/modules/markets/markets.service';
import MarketsHistoryService from '@/modules/common/modules/markets-history/markets-history.service';
import MarketsHistoryApiService, { MarketsHistoryApiServiceS } from '@/modules/common/modules/markets-history/markets-history-api.service';
import RoomTypesApiService, { RoomTypesApiServiceS } from '@/modules/room-types/room-types-api.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import HotelsApiService, { HotelsApiServiceS } from '@/modules/hotels/hotels-api.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import CompsetsApiService, { CompsetsApiServiceS } from '@/modules/compsets/compsets-api.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import DeepCompsetAnalysisClusterService, { DeepCompsetAnalysisClusterServiceS } from '@/modules/cluster/cluster-deep-analysis.service';
import DeepCompsetAnalysisCommonService, { DeepCompsetAnalysisCommonServiceS } from '@/modules/deep-analysis/deep-analysis-common.service';
import DeepCompsetAnalysisApiService, { DeepCompsetAnalysisApiServiceS } from '@/modules/deep-analysis/deep-analysis-api.service';
import DeepCompsetAnalysisService from '@/modules/deep-analysis/deep-analysis.service';
import MealTypesApiService, { MealTypesApiServiceS } from '@/modules/meal-types/meal-types-api.service';
import RankingService from '@/modules/ranking/ranking.service';
import RankingApiService, { RankingApiServiceS } from '@/modules/ranking/ranking-api.service';
import RankingCommonService, { RankingCommonServiceS } from '@/modules/common/modules/ranking/ranking-common.service';
import RankingHistoryService, { RankingHistoryServiceS } from '@/modules/common/modules/ranking-history/ranking-history.service';
import RankingHistoryApiService, { RankingHistoryApiServiceS } from '@/modules/common/modules/ranking-history/ranking-history-api.service';
import ExceptionHandler, { ExceptionHandlerS } from '@/modules/common/modules/exception-handler/exception-handler.service';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import NumberOfGuestsApiService, { NumberOfGuestsApiServiceS } from '@/modules/number-of-guests/number-of-guests-api.service';
import NumberOfGuestsService, { NumberOfGuestsServiceS } from '@/modules/number-of-guests/number-of-guests.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import CarsAlertService, { CarsAlertServiceS } from '@/modules/cars/car.alert.service';
import CarsAlertApiService, { CarsAlertApiServiceS } from '@/modules/cars/car.alert-api.service';
import CarsFilterApiService, { CarsFilterApiServiceS } from '@/modules/cars/cars-filter-api.service';
import CarsApiService, { CarsApiServiceS } from '@/modules/cars/cars-api.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import ParityApiService, { ParityApiServiceS } from '@/modules/cars/modules/parity/parity-api.service';
import ParityService, { ParityServiceS } from '@/modules/cars/modules/parity/parity.service';
import ParitySettingsService, { ParitySettingsServiceS } from '@/modules/cars/modules/parity/parity-settings.service';
import ParityFiltersService, { ParityFiltersServiceS } from '@/modules/cars/modules/parity/parity-filters.service';
import LocationAvailabilityApiService, { LocationAvailabilityApiServiceS }
    from '@/modules/cars/modules/location-availability/location-availability-api.service';
import LocationAvailabilityFiltersService, { LocationAvailabilityFiltersServiceS }
    from '@/modules/cars/modules/location-availability/location-availability-filters.service';
import LocationAvailabilityService, { LocationAvailabilityServiceS }
    from '@/modules/cars/modules/location-availability/location-availability.service';
import AlertsService, { AlertsServiceS } from '@/modules/alerts/alerts.service';
import CarsAlertsService, { CarsAlertsServiceS } from '@/modules/cars/alerts/cars-alerts.service';
import AlertsApiService, { AlertsApiServiceS } from '@/modules/alerts/alerts-api.service';
import CarsAlertsApiService, { CarsAlertsApiServiceS } from '@/modules/cars/alerts/cars-alerts-api.service';
import RoomsTypeManagerApiService, { RoomsTypeManagerApiServiceS } from '@/modules/rooms-type-manager/rooms-type-manager-api.service';
import RoomsTypeManagerService, { RoomsTypeManagerServiceS } from '@/modules/rooms-type-manager/rooms-type-manager.service';
import HomeFiltersService, { HomeFiltersServiceS } from '@/modules/home/home-filters.service';
import HotelHomepageService, { HotelHomepageServiceS } from '@/modules/home/march-demo/hotel-homepage.service';
import CarsPriceHistoryService, { CarsPriceHistoryServiceS } from '@/modules/cars/cars-price-history.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import HeadService, { HeadServiceS } from '@/modules/common/services/head.service';

import RatesPriceHistoryService, { RatesPriceHistoryServiceS } from '@/modules/price-history/rates-price-history.service';

import RatesPriceHistoryCommonService
    from '@/modules/price-history/rates-price-history-common.service';
import RatesPriceHistoryAllService, { RatesPriceHistoryAllServiceS }
    from '@/modules/price-history/rates-price-history-all.service';
import RatesPriceHistoryApiService, {
    RatesPriceHistoryApiServiceS,
} from '@/modules/price-history/rates-price-history-api.service';

import MarketsFiltersService from '@/modules/markets/markets-filters.service';
import RatesFiltersService from '@/modules/rates/rates-filters.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import ProvidersApiService, { ProvidersApiServiceS } from '@/modules/providers/providers-api.service';
import SocketService, { SocketServiceS } from '@/modules/common/modules/socket/socket.service';
import CarsTableauService, { CarsTableauServiceS } from '@/modules/cars/modules/tableau/cars-tableau.service';
import ScoreService, { ScoreServiceS } from '@/modules/score/score.service';
import ScoreApiService, { ScoreApiServiceS } from '@/modules/score/score-api.service';
import ValidatorService, { ValidatorServiceS } from '@/modules/common/services/validator.service';
import HotelCatalogService, { HotelCatalogServiceS } from '@/modules/hotels/modules/hotel-catalog/hotel-catalog.service';
import HotelCatalogApiService, { HotelCatalogApiServiceS } from '@/modules/hotels/modules/hotel-catalog/hotel-catalog-api.service';
import ClusterCompsetsService, { ClusterCompsetsServiceS } from '@/modules/cluster/cluster-compsets.service';

import ClusterRatesService from '@/modules/cluster/cluster-rates.service';
import ClusterMarketsService from '@/modules/cluster/cluster-markets.service';
import ClusterRankingService, { ClusterRankingServiceS } from '@/modules/cluster/cluster-ranking.service';
import CarsCategoryManagerApiService, { CarsCategoryManagerApiServiceS } from '@/modules/cars-category-manager/cars-category-manager-api.service';
import CarsCategoryManagerService, { CarsCategoryManagerServiceS } from '@/modules/cars-category-manager/cars-category-manager.service';

import ReportsService, { ReportsServiceS } from '@/modules/scheduled-reports/reports.service';
import ReportsApiService, { ReportsApiServiceS } from '@/modules/scheduled-reports/reports-api.service';

import DownloadExcelDirectlyMiddleware, { DownloadExcelDirectlyMiddlewareS } from '@/router/middlewares/download-excel-directly.middleware';
import SippService, { SippServiceS } from '@/modules/cars/modules/sipp/sipp.service';
import SippApiService, { SippApiServiceS } from '@/modules/cars/modules/sipp/sipp-api.service';
import SippSettingsService, { SippSettingsServiceS } from '@/modules/cars/modules/sipp/sipp-settings.service';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import FleetApiService, { FleetApiServiceS } from '@/modules/cars/modules/fleet/fleet-api.service';
import FleetFilterService, { FleetFilterServiceS } from '@/modules/cars/modules/fleet/fleet-filter.service';
import FleetFilterApiService, { FleetFilterApiServiceS } from '@/modules/cars/modules/fleet/fleet-filter-api.service';
import FleetHistoryService, { FleetHistoryServiceS } from '@/modules/cars/modules/cars-price-history/fleet-history.service';
import ClusterService from '@/modules/cluster/cluster.service';
import ClusterApiService, { ClusterApiServiceS } from '@/modules/cluster/cluster-api.service';
import DiLiteAllChannelsService from '@/modules/di-lite/di-lite-all-channels.service';
import DiLiteMarketService from '@/modules/di-lite/di-lite-market.service';
import DiLiteMarketApiService, { DiLiteMarketApiServiceS } from '@/modules/di-lite/di-lite-market-api.service';
import CarsPriceHistoryApi, { CarsPriceHistoryApiS } from '@/modules/cars/cars-price-history-api.service';
import WalkmeService, { WalkmeServiceS } from '@/modules/walkme/walkme.service';
import WhatfixService, { WhatfixServiceS } from '@/modules/whatfix/whatfix.service';
import MarketApiService, { MarketApiServiceS } from '@/modules/market/market-api.service';
import MarketService, { MarketServiceS } from '@/modules/market/market.service';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import store from './store/index';
import { StoreS } from './store/types';
import ChainGuard, { ChainGuardS } from './router/guards/chain.guard';
import CacheService, { CacheServiceS } from './modules/common/services/cache/cache.service';
import ClusterScoresService, { ClusterScoresServiceS } from './modules/cluster/modules/score/score.service';
import ClusterScoreApiService, { ClusterScoreApiServiceS } from './modules/cluster/modules/score/score-api.service';
import ChainRatesService, { ChainRatesServiceS } from './modules/chain/modules/rates/chain-rates.service';
import ChainApiService, { ChainApiServiceS } from './modules/chain/chain-api.service';
import ChainMarketsService, { ChainMarketsServiceS } from './modules/chain/modules/markets/chain-markets.service';
import ChainService, { ChainServiceS } from './modules/chain/chain.service';

import PromotionsService from './modules/promotions/promotions.service';
import PromotionsApiService, { PromotionsApiServiceS } from './modules/promotions/promotions-api.service';
import PromotionsHistoryService, { PromotionsHistoryServiceS } from './modules/promotions/promotion-history.service';
import CarsAnalysisFiltersService, { CarsAnalysisFiltersServiceS } from './modules/cars/cars-analysis-filters.service';
import CarsAnalysisService, { CarsAnalysisServiceS } from './modules/cars/cars-analysis.service';
import CarsRateAlertApiService, { CarsRateAlertApiServiceS } from './modules/cars/car.rate-alert-api.service';
import CarsRateAlertService, { CarsRateAlertServiceS } from './modules/cars/car.rate-alert.service';
import ClusterPromotionsService from './modules/cluster/cluster-promotions.service';
import ClusterDiLiteService from './modules/cluster/cluster-di-lite.service';
import OpenTelemetryService, { OpenTelemetryServiceS } from './modules/open-telemetry/open-telemetry.service';
import InsightsService from './modules/insights/insights.service';
import InsightsApiService, { InsightsApiServiceS } from './modules/insights/insights-api.service';
import TranslationsService, { TranslationsServiceS } from './modules/translations/translations.service';
import TranslationsApiService, { TranslationsApiServiceS } from './modules/translations/translations-api.service';
import ClusterInsightsService, { ClusterInsightsServiceS } from './modules/cluster/cluster-insights.service';
import RateValueScoreApiService from './modules/rvs/rate-value-score-api.service';
import RateValueScoreService from './modules/rvs/rate-value-score.service';
import RVSCalendarService from './modules/rvs/rvs-calendar.service';
import RVSOvertimeService from './modules/rvs/rvs-overtime.service';
import RVSScoreService from './modules/rvs/rvs-score.service';
import RVSDetailsService from './modules/rvs/rvs-details.service';
import { HotelRateTrendsService } from './modules/price-history/hotel-rate-trends.service';
import SettingsApiService from './modules/settings/settings-api.service';
import RVSWeightSettingsService from './modules/settings/rvs/rvs-weight-settings.service';
import ClusterRvsService from './modules/rvs/cluster-rvs.service';
import { SettingsGeneralService } from './modules/settings/settings-general.service';
import { SettingsHotelService } from './modules/settings/settings-hotel.service';

export function bootstrapContainer() {
    container.bind(StoreS).toConstantValue(store);
    container.bind<ApiService>(ApiServiceS).to(ApiService).inSingletonScope();
    container.bind<SocketService>(SocketServiceS).to(SocketService).inSingletonScope();
    container.bind<NotifyService>(NotifyServiceS).to(NotifyService).inSingletonScope();
    container.bind<CustomNotificationService>(CustomNotificationServiceS).to(CustomNotificationService).inSingletonScope();
    container.bind<CustomNotificationApiService>(CustomNotificationApiServiceS).to(CustomNotificationApiService).inSingletonScope();
    container.bind<ConfigService>(ConfigServiceS).to(ConfigService).inSingletonScope();
    container.bind<ExceptionHandler>(ExceptionHandlerS).to(ExceptionHandler).inSingletonScope();
    container.bind<HelperService>(HelperServiceS).to(HelperService).inSingletonScope();
    container.bind<ValidatorService>(ValidatorServiceS).to(ValidatorService).inSingletonScope();
    container.bind<HeadService>(HeadServiceS).to(HeadService).inSingletonScope();
    container.bind<CacheService>(CacheServiceS).to(CacheService).inSingletonScope();
    container.bind<RatesFiltersMiddleware>(RatesFiltersMiddlewareS).to(RatesFiltersMiddleware).inSingletonScope();
    container.bind<PaginationMiddleware>(PaginationMiddlewareS).to(PaginationMiddleware).inSingletonScope();
    container.bind<MarketsFiltersMiddleware>(MarketsFiltersMiddlewareS).to(MarketsFiltersMiddleware).inSingletonScope();
    container.bind<HomepageRedirectMiddleware>(HomepageRedirectMiddlewareS).to(HomepageRedirectMiddleware).inSingletonScope();
    container.bind<AuthMiddleware>(AuthMiddlewareS).to(AuthMiddleware).inSingletonScope();
    container.bind<LevelMiddleware>(LevelMiddlewareS).to(LevelMiddleware).inSingletonScope();
    container.bind<OnboardingMiddleware>(OnboardingMiddlewareS).to(OnboardingMiddleware).inSingletonScope();
    container.bind<ViewAsMiddleware>(ViewAsMiddlewareS).to(ViewAsMiddleware).inSingletonScope();
    container.bind<ViewAsChainGuard>(ViewAsChainGuardS).to(ViewAsChainGuard).inSingletonScope();
    container.bind<ViewAsHotelGuard>(ViewAsHotelGuardS).to(ViewAsHotelGuard).inSingletonScope();
    container.bind<CarGuard>(CarGuardS).to(CarGuard).inSingletonScope();
    container.bind<HotelGuard>(HotelGuardS).to(HotelGuard).inSingletonScope();
    container.bind<ClusterOrChainGuard>(ClusterOrChainGuardS).to(ClusterOrChainGuard).inSingletonScope();
    container.bind<HotelOrCarGuard>(HotelOrCarGuardS).to(HotelOrCarGuard).inSingletonScope();
    container.bind<ClusterGuard>(ClusterGuardS).to(ClusterGuard).inSingletonScope();
    container.bind<FeaturesGuard>(FeaturesGuardS).to(FeaturesGuard).inSingletonScope();
    container.bind<ChainGuard>(ChainGuardS).to(ChainGuard).inSingletonScope();
    container.bind<StorageService>(StorageServiceS).to(StorageService).inSingletonScope();
    container.bind<TokenSessionStorageService>(TokenSessionStorageServiceS).to(TokenSessionStorageService).inSingletonScope();
    container.bind<UserService>(UserServiceS).to(UserService).inSingletonScope();
    container.bind<UserApiService>(UserApiServiceS).to(UserApiService).inSingletonScope();
    container.bind<UserNavigationService>(UserNavigationServiceS).to(UserNavigationService).inSingletonScope();
    container.bind<UserViewService>(UserViewServiceS).to(UserViewService).inSingletonScope();
    container.bind<AuthService>(AuthServiceS).to(AuthService).inSingletonScope();
    container.bind<DocumentFiltersService>(KEY.DocumentFiltersService).to(DocumentFiltersService).inSingletonScope();
    container.bind<InsightFiltersService>(KEY.InsightFiltersService).to(InsightFiltersService).inSingletonScope();
    container.bind<NumberOfGuestsService>(NumberOfGuestsServiceS).to(NumberOfGuestsService).inSingletonScope();
    container.bind<NumberOfGuestsApiService>(NumberOfGuestsApiServiceS).to(NumberOfGuestsApiService).inSingletonScope();
    container.bind<MealTypesService>(MealTypesServiceS).to(MealTypesService).inSingletonScope();
    container.bind<MealTypesApiService>(MealTypesApiServiceS).to(MealTypesApiService).inSingletonScope();
    container.bind<RatesApiService>(RatesApiServiceS).to(RatesApiService).inSingletonScope();
    container.bind<RatesCommonService>(RatesCommonServiceS).to(RatesCommonService).inSingletonScope();
    container.bind<RatesService>(KEY.RatesService).to(RatesService).inSingletonScope();
    container.bind<RatesAnalysisService>(RatesAnalysisServiceS).to(RatesAnalysisService).inSingletonScope();
    container.bind<RatesAnalysisFiltersService>(RatesAnalysisFiltersServiceS).to(RatesAnalysisFiltersService).inSingletonScope();
    container.bind<RatesAllService>(RatesAllServiceS).to(RatesAllService).inSingletonScope();
    container.bind<RatesFiltersService>(KEY.RatesFiltersService).to(RatesFiltersService).inSingletonScope();
    container.bind<RatesPriceHistoryService>(RatesPriceHistoryServiceS).to(RatesPriceHistoryService).inSingletonScope();
    container.bind<RatesPriceHistoryCommonService>(KEY.RatesPriceHistoryCommonService).to(RatesPriceHistoryCommonService).inSingletonScope();
    container.bind<RatesPriceHistoryAllService>(RatesPriceHistoryAllServiceS).to(RatesPriceHistoryAllService).inSingletonScope();
    container.bind<RatesPriceHistoryApiService>(RatesPriceHistoryApiServiceS).to(RatesPriceHistoryApiService).inSingletonScope();
    container.bind<MarketsApiService>(MarketsApiServiceS).to(MarketsApiService).inSingletonScope();
    container.bind<MarketsService>(KEY.MarketsService).to(MarketsService).inSingletonScope();
    container.bind<MarketsFiltersService>(KEY.MarketsFiltersService).to(MarketsFiltersService).inSingletonScope();
    container.bind<MarketsCommonService>(MarketsCommonServiceS).to(MarketsCommonService).inSingletonScope();
    container.bind<MarketsHistoryApiService>(MarketsHistoryApiServiceS).to(MarketsHistoryApiService).inSingletonScope();
    container.bind<MarketsHistoryService>(KEY.MarketsHistoryService).to(MarketsHistoryService).inSingletonScope();
    container.bind<MarketApiService>(MarketApiServiceS).to(MarketApiService).inSingletonScope();
    container.bind<MarketService>(MarketServiceS).to(MarketService).inSingletonScope();
    container.bind<CompsetsApiService>(CompsetsApiServiceS).to(CompsetsApiService).inSingletonScope();
    container.bind<CompsetsService>(CompsetsServiceS).to(CompsetsService).inSingletonScope();
    container.bind<DeepCompsetAnalysisCommonService>(DeepCompsetAnalysisCommonServiceS).to(DeepCompsetAnalysisCommonService).inSingletonScope();
    container.bind<DeepCompsetAnalysisClusterService>(DeepCompsetAnalysisClusterServiceS).to(DeepCompsetAnalysisClusterService).inSingletonScope();
    container.bind<DeepCompsetAnalysisService>(KEY.DeepCompsetAnalysisService).to(DeepCompsetAnalysisService).inSingletonScope();
    container.bind<DeepCompsetAnalysisApiService>(DeepCompsetAnalysisApiServiceS).to(DeepCompsetAnalysisApiService).inSingletonScope();
    container.bind<RoomTypesApiService>(RoomTypesApiServiceS).to(RoomTypesApiService).inSingletonScope();
    container.bind<RoomTypesService>(RoomTypesServiceS).to(RoomTypesService).inSingletonScope();
    container.bind<HotelsApiService>(HotelsApiServiceS).to(HotelsApiService).inSingletonScope();
    container.bind<HotelsService>(HotelsServiceS).to(HotelsService).inSingletonScope();
    container.bind<RoomsTypeManagerApiService>(RoomsTypeManagerApiServiceS).to(RoomsTypeManagerApiService).inSingletonScope();
    container.bind<RoomsTypeManagerService>(RoomsTypeManagerServiceS).to(RoomsTypeManagerService).inSingletonScope();
    container.bind<RankingService>(KEY.RankingService).to(RankingService).inSingletonScope();
    container.bind<RankingApiService>(RankingApiServiceS).to(RankingApiService).inSingletonScope();
    container.bind<RankingHistoryService>(RankingHistoryServiceS).to(RankingHistoryService).inSingletonScope();
    container.bind<RankingHistoryApiService>(RankingHistoryApiServiceS).to(RankingHistoryApiService).inSingletonScope();
    container.bind<RankingCommonService>(RankingCommonServiceS).to(RankingCommonService).inSingletonScope();
    container.bind<ProvidersService>(ProvidersServiceS).to(ProvidersService).inSingletonScope();
    container.bind<ProvidersApiService>(ProvidersApiServiceS).to(ProvidersApiService).inSingletonScope();
    container.bind<EventsFilterService>(EventsFilterServiceS).to(EventsFilterService).inSingletonScope();
    container.bind<EventsManagerService>(EventsManagerServiceS).to(EventsManagerService).inSingletonScope();
    container.bind<EventsApiService>(EventsApiServiceS).to(EventsApiService).inSingletonScope();
    container.bind<StoreFacade>(StoreFacadeS).to(StoreFacade).inSingletonScope();
    container.bind<JiraService>(KEY.JiraService).to(JiraService).inSingletonScope();
    container.bind<WalkmeService>(WalkmeServiceS).to(WalkmeService).inSingletonScope();
    container.bind<WhatfixService>(WhatfixServiceS).to(WhatfixService).inSingletonScope();
    container.bind<OpenTelemetryService>(OpenTelemetryServiceS).to(OpenTelemetryService).inSingletonScope();
    container.bind<ScoreService>(ScoreServiceS).to(ScoreService).inSingletonScope();
    container.bind<ScoreApiService>(ScoreApiServiceS).to(ScoreApiService).inSingletonScope();
    container.bind<HotelCatalogService>(HotelCatalogServiceS).to(HotelCatalogService).inSingletonScope();
    container.bind<HotelCatalogApiService>(HotelCatalogApiServiceS).to(HotelCatalogApiService).inSingletonScope();
    container.bind<ReportsService>(ReportsServiceS).to(ReportsService).inSingletonScope();
    container.bind<ReportsApiService>(ReportsApiServiceS).to(ReportsApiService).inSingletonScope();
    container.bind<DownloadExcelDirectlyMiddleware>(DownloadExcelDirectlyMiddlewareS).to(DownloadExcelDirectlyMiddleware).inSingletonScope();
    container.bind<CompsetSettingsService>(CompsetSettingsServiceS).to(CompsetSettingsService).inSingletonScope();
    container.bind<HotelHomepageService>(HotelHomepageServiceS).to(HotelHomepageService).inSingletonScope();
    container.bind<InsightsService>(KEY.InsightsService).to(InsightsService).inSingletonScope();
    container.bind<InsightsApiService>(InsightsApiServiceS).to(InsightsApiService).inSingletonScope();
    container.bind<TranslationsService>(TranslationsServiceS).to(TranslationsService).inSingletonScope();
    container.bind<TranslationsApiService>(TranslationsApiServiceS).to(TranslationsApiService).inSingletonScope();

    // Car
    container.bind<CarsService>(CarsServiceS).to(CarsService).inSingletonScope();
    container.bind<CarsApiService>(CarsApiServiceS).to(CarsApiService).inSingletonScope();
    container.bind<CarsAlertService>(CarsAlertServiceS).to(CarsAlertService).inSingletonScope();
    container.bind<CarsAlertApiService>(CarsAlertApiServiceS).to(CarsAlertApiService).inSingletonScope();
    container.bind<CarsRateAlertApiService>(CarsRateAlertApiServiceS).to(CarsRateAlertApiService).inSingletonScope();
    container.bind<CarsRateAlertService>(CarsRateAlertServiceS).to(CarsRateAlertService).inSingletonScope();
    container.bind<CarsCategoryManagerApiService>(CarsCategoryManagerApiServiceS).to(CarsCategoryManagerApiService).inSingletonScope();
    container.bind<CarsCategoryManagerService>(CarsCategoryManagerServiceS).to(CarsCategoryManagerService).inSingletonScope();
    container.bind<CarsFiltersService>(CarsFiltersServiceS).to(CarsFiltersService).inSingletonScope();
    container.bind<CarsFilterApiService>(CarsFilterApiServiceS).to(CarsFilterApiService).inSingletonScope();
    container.bind<CarsPriceHistoryService>(CarsPriceHistoryServiceS).to(CarsPriceHistoryService).inSingletonScope();
    container.bind<CarsPriceHistoryApi>(CarsPriceHistoryApiS).to(CarsPriceHistoryApi).inSingletonScope();
    container.bind<CarsSharedService>(CarsSharedServiceS).to(CarsSharedService).inSingletonScope();
    container.bind<CarsTableauService>(CarsTableauServiceS).to(CarsTableauService).inSingletonScope();
    container.bind<ParityApiService>(ParityApiServiceS).to(ParityApiService).inSingletonScope();
    container.bind<ParityService>(ParityServiceS).to(ParityService).inSingletonScope();
    container.bind<ParitySettingsService>(ParitySettingsServiceS).to(ParitySettingsService).inSingletonScope();
    container.bind<ParityFiltersService>(ParityFiltersServiceS).to(ParityFiltersService).inSingletonScope();
    container.bind<LocationAvailabilityApiService>(LocationAvailabilityApiServiceS).to(LocationAvailabilityApiService).inSingletonScope();
    container.bind<LocationAvailabilityService>(LocationAvailabilityServiceS).to(LocationAvailabilityService).inSingletonScope();
    container.bind<LocationAvailabilityFiltersService>(LocationAvailabilityFiltersServiceS).to(LocationAvailabilityFiltersService).inSingletonScope();
    container.bind<AlertsService>(AlertsServiceS).to(AlertsService).inSingletonScope();
    container.bind<AlertsApiService>(AlertsApiServiceS).to(AlertsApiService).inSingletonScope();
    container.bind<CarsAlertsService>(CarsAlertsServiceS).to(CarsAlertsService).inSingletonScope();
    container.bind<CarsAlertsApiService>(CarsAlertsApiServiceS).to(CarsAlertsApiService).inSingletonScope();
    container.bind<HomeFiltersService>(HomeFiltersServiceS).to(HomeFiltersService).inSingletonScope();
    container.bind<HomeService>(KEY.HomeService).to(HomeService).inSingletonScope();
    container.bind<ClusterRatesService>(KEY.ClusterRatesService).to(ClusterRatesService).inSingletonScope();
    container.bind<ClusterMarketsService>(KEY.ClusterMarketsService).to(ClusterMarketsService).inSingletonScope();
    container.bind<ClusterRankingService>(ClusterRankingServiceS).to(ClusterRankingService).inSingletonScope();
    container.bind<ClusterDiLiteService>(KEY.ClusterDiLiteService).to(ClusterDiLiteService).inSingletonScope();
    container.bind<ClusterScoresService>(ClusterScoresServiceS).to(ClusterScoresService).inSingletonScope();
    container.bind<ClusterScoreApiService>(ClusterScoreApiServiceS).to(ClusterScoreApiService).inSingletonScope();
    container.bind<ClusterInsightsService>(ClusterInsightsServiceS).to(ClusterInsightsService).inSingletonScope();
    container.bind<SippService>(SippServiceS).to(SippService).inSingletonScope();
    container.bind<SippApiService>(SippApiServiceS).to(SippApiService).inSingletonScope();
    container.bind<SippSettingsService>(SippSettingsServiceS).to(SippSettingsService).inSingletonScope();
    container.bind<FleetService>(FleetServiceS).to(FleetService).inSingletonScope();
    container.bind<FleetApiService>(FleetApiServiceS).to(FleetApiService).inSingletonScope();
    container.bind<FleetHistoryService>(FleetHistoryServiceS).to(FleetHistoryService).inSingletonScope();
    container.bind<FleetFilterService>(FleetFilterServiceS).to(FleetFilterService).inSingletonScope();
    container.bind<FleetFilterApiService>(FleetFilterApiServiceS).to(FleetFilterApiService).inSingletonScope();
    container.bind<CarsAnalysisFiltersService>(CarsAnalysisFiltersServiceS).to(CarsAnalysisFiltersService).inSingletonScope();
    container.bind<CarsAnalysisService>(CarsAnalysisServiceS).to(CarsAnalysisService).inSingletonScope();

    // cluster
    container.bind<ClusterService>(KEY.ClusterService).to(ClusterService).inSingletonScope();
    container.bind<ClusterCompsetsService>(ClusterCompsetsServiceS).to(ClusterCompsetsService).inSingletonScope();
    container.bind<ClusterApiService>(ClusterApiServiceS).to(ClusterApiService).inSingletonScope();

    // chain
    container.bind<ChainService>(ChainServiceS).to(ChainService).inSingletonScope();
    container.bind<ChainRatesService>(ChainRatesServiceS).to(ChainRatesService).inSingletonScope();
    container.bind<ChainMarketsService>(ChainMarketsServiceS).to(ChainMarketsService).inSingletonScope();
    container.bind<ChainApiService>(ChainApiServiceS).to(ChainApiService).inSingletonScope();

    // di-light
    container.bind<DiLiteAllChannelsService>(KEY.DiLiteAllChannelsService).to(DiLiteAllChannelsService).inSingletonScope();
    container.bind<DiLiteMarketService>(KEY.DiLiteMarketService).to(DiLiteMarketService).inSingletonScope();
    container.bind<DiLiteMarketApiService>(DiLiteMarketApiServiceS).to(DiLiteMarketApiService).inSingletonScope();

    // promotions
    container.bind<PromotionsService>(KEY.PromotionsService).to(PromotionsService).inSingletonScope();
    container.bind<PromotionsHistoryService>(PromotionsHistoryServiceS).to(PromotionsHistoryService).inSingletonScope();
    container.bind<PromotionsApiService>(PromotionsApiServiceS).to(PromotionsApiService).inSingletonScope();
    container.bind<ClusterPromotionsService>(KEY.ClusterPromotionsService).to(ClusterPromotionsService).inSingletonScope();

    // Rates Price History
    container.bind<HotelRateTrendsService>(KEY.HotelRateTrendsService).to(HotelRateTrendsService).inSingletonScope();

    // Rate value score
    container.bind<RateValueScoreApiService>(KEY.RateValueScoreApiService).to(RateValueScoreApiService).inSingletonScope();
    container.bind<RateValueScoreService>(KEY.RateValueScoreSerivce).to(RateValueScoreService).inSingletonScope();
    container.bind<RVSCalendarService>(KEY.RVSCalendarService).to(RVSCalendarService).inSingletonScope();
    container.bind<RVSOvertimeService>(KEY.RVSOvertimeService).to(RVSOvertimeService).inSingletonScope();
    container.bind<RVSScoreService>(KEY.RVSScoreService).to(RVSScoreService).inSingletonScope();
    container.bind<RVSDetailsService>(KEY.RVSDetailsService).to(RVSDetailsService).inSingletonScope();
    container.bind<ClusterRvsService>(KEY.ClusterRvsService).to(ClusterRvsService).inSingletonScope();

    // Settings
    container.bind<SettingsApiService>(KEY.SettingsApiService).to(SettingsApiService).inSingletonScope();
    container.bind<SettingsGeneralService>(KEY.SettingsGeneralService).to(SettingsGeneralService).inSingletonScope();
    container.bind<SettingsHotelService>(KEY.SettingsHotelService).to(SettingsHotelService).inSingletonScope();
    container.bind<RVSWeightSettingsService>(KEY.RVSWeightSettingsService).to(RVSWeightSettingsService).inSingletonScope();
}

export async function loadAsyncModules() {
    const configModule = new AsyncContainerModule(async () => {
        const configService = container.get<ConfigService>(ConfigServiceS);
        await configService.init();
        container.rebind(ConfigServiceS).toConstantValue(configService);
    });

    await container.loadAsync(configModule);

    const exceptionModule = new AsyncContainerModule(async () => {
        const exceptionService = container.get<ExceptionHandler>(ExceptionHandlerS);
        container.rebind(ExceptionHandlerS).toConstantValue(exceptionService);
    });

    await container.loadAsync(exceptionModule);

    // This module are too important and we can't build dynamic loading there - too expensive
    const documentFiltersModule = new AsyncContainerModule(async () => {
        const documentFiltersService = container.get<DocumentFiltersService>(KEY.DocumentFiltersService);
        container.rebind(KEY.DocumentFiltersService).toConstantValue(documentFiltersService);
    });

    await container.loadAsync(documentFiltersModule);
}
