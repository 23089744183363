import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';

import ClusterCompsetsService, { ClusterCompsetsServiceS } from '@/modules/cluster/cluster-compsets.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import HotelsApiService, { HotelsApiServiceS } from '@/modules/hotels/hotels-api.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';

import Stateable from '@/modules/common/interfaces/stateable.interface';
import { hexToRgb } from '@/modules/common/constants/default-graph-colors.constant';
import HotelsStore from './store/hotels.store';
import HotelModel from './models/hotel.model';
import CacheService, { CacheServiceS, HOTELS_METHODS, MODULES } from '../common/services/cache/cache.service';

import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import { SettingsGeneralService } from '../settings/settings-general.service';

export const HotelsServiceS = Symbol.for('HotelsServiceS');
@injectable()
export default class HotelsService implements Stateable {
    @inject(HotelsApiServiceS) private hotelsApiService!: HotelsApiService;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(ClusterCompsetsServiceS) private clusterCompsetsService!: ClusterCompsetsService;
    @inject(UserServiceS) private userService!: UserService;
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(HelperServiceS) private helperService!: HelperService;
    @inject(CacheServiceS) private cacheService!: CacheService;
    @inject(KEY.SettingsGeneralService) private settingsGeneralService!: SettingsGeneralService;

    readonly storeState: HotelsStore = this.storeFacade.getState('HotelsStore');

    missedHotelIds: number[] = [];

    constructor() {
        this.storeFacade.watch(
            () => [
                this.userService.isUserLoaded,
                this.userService.currentHotelId,
            ],
            this.storeState.loading.reset.bind(this.storeState.loading),
        );

        this.getHotelNames = this.cacheService
            .memorize(MODULES.HOTELS, HOTELS_METHODS.getHotelNames, this.getHotelNames.bind(this));
    }

    async loadUserHotels() {
        const myHotels = await this.hotelsApiService.getMyHotels();
        this.storeState.myHotels = myHotels || [];
        return true;
    }

    async loadHotelCompetitors() {
        const { currentHotelId } = this.userService;
        if (currentHotelId === null) {
            return false;
        }

        const hotels = await this.hotelsApiService.getHotels(currentHotelId);

        this.storeState.hotels = hotels || [];
        this.missedHotelIds = [];

        return true;
    }

    async loadData() {
        const { currentHotelId } = this.userService;
        if (currentHotelId === null) {
            return false;
        }

        const [hotels, myHotels] = await Promise.all([this.hotelsApiService.getHotels(currentHotelId), this.hotelsApiService.getMyHotels()]);
        this.storeState.hotels = hotels || [];
        this.storeState.myHotels = myHotels || [];
        this.missedHotelIds = [];

        return true;
    }

    get myHotels() {
        this.helperService.dynamicLoading(this.storeState.userHotelsLoading, this.loadUserHotels.bind(this));
        let myHotels = [...this.storeState.myHotels];

        myHotels = myHotels.sort((x, y) => {
            if (x.name.toLowerCase() < y.name.toLowerCase()) { return -1; }
            if (x.name.toLowerCase() > y.name.toLowerCase()) { return 1; }
            return 0;
        });

        return myHotels;
    }

    get allHotels() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.hotels;
    }

    addLocalHotel(hotel: HotelModel) {
        this.storeState.hotels.push(hotel);
    }

    getHotelName(hotelId: number, disableHotelLoading: boolean = false) {
        if (!hotelId) {
            return '';
        }

        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));

        const { hotels, myHotels, missedHotels } = this.storeState;

        const searchedHotel = [...myHotels, ...hotels, ...missedHotels].find(hotel => hotel.id === hotelId);

        // 1 request per 1 missing name. Temporary solution
        if (!searchedHotel && !disableHotelLoading && !this.missedHotelIds.some(id => id === hotelId)) {
            this.loadHotel(hotelId);
            this.missedHotelIds.push(hotelId);
            return `${hotelId}`;
        }

        return searchedHotel ? searchedHotel.name : String(hotelId);
    }

    async getHotelNames(hotelIds: number[]) {
        const data = await this.hotelsApiService.getHotelsById(hotelIds);

        if (!data) return {};

        return Object.fromEntries(data.map(hotelData => [hotelData.id, hotelData.name]));
    }

    getHotelGeoLocation(hotelId: number) {
        if (!hotelId) {
            return { lat: 0, lng: 0 };
        }

        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));

        const { hotels, myHotels, missedHotels } = this.storeState;
        const searchedHotel = [...hotels, ...myHotels, ...missedHotels].find(hotel => hotel.id === hotelId);

        // 1 request per 1 missing name. Temporary solution
        if (!searchedHotel && !this.missedHotelIds.some(id => id === hotelId)) {
            this.loadHotel(hotelId);
            this.missedHotelIds.push(hotelId);
            return { lat: 0, lng: 0 };
        }

        return (searchedHotel && searchedHotel.geoLocation) ? searchedHotel.geoLocation : { lat: 0, lng: 0 };
    }

    protected async loadHotel(notFoundHotelId: number) {
        const DEFAULT_HOTEL = {
            id: notFoundHotelId,
            name: String(notFoundHotelId),
        };

        const newHotels = await this.hotelsApiService
            .getHotelsById([notFoundHotelId]) || [DEFAULT_HOTEL];

        const missedHotels = [...this.storeState.missedHotels];
        const updatedHotels = missedHotels.concat(newHotels);

        const uniqueUpdatedHotelIds = Array
            .from(new Set(updatedHotels.map(hotel => hotel.id)));

        this.storeState.missedHotels = uniqueUpdatedHotelIds
            .map(hotelId => (
                updatedHotels.find(hotel => hotel.id === hotelId)
            ))
            .filter(hotel => hotel) as HotelModel[];
    }

    getHotelsGraphColor(compsetId?: string | null): { [hotelId: string]: string } {
        const { chartColors } = this.settingsGeneralService;
        const colors: { [hotelId: string]: string } = {};

        this.getHotelList(compsetId)
            .forEach((hotelId, index) => {
                colors[hotelId] = chartColors[index];
            });

        return colors;
    }

    getHotelsGraphColorRgb(compsetId?: string | null): { [hotelId: string]: string } {
        const colors: { [hotelId: string]: string } = {};
        const { chartColors } = this.settingsGeneralService;

        if (chartColors === null) {
            return colors;
        }

        this.getHotelList(compsetId).forEach((hotelId, index) => {
            colors[hotelId] = hexToRgb(chartColors[index]);
        });
        return colors;
    }

    getHotelList(compsetId?: string | null) {
        const compset = this.compsetsService.getCompset(compsetId!)
            || this.clusterCompsetsService.getCompsetById(compsetId!)
            || this.compsetsService.currentCompset;

        return compset
            ? compset.competitors
            : [];
    }

    registerHotels(hotels: Record<number, string>) {
        Object
            .entries(hotels)
            .forEach(([hotelId, hotelName]) => {
                const instance = Object.assign(new HotelModel(), {
                    id: parseInt(hotelId, 10),
                    name: hotelName,
                });

                this.addLocalHotel(instance);
            });
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get isUserHotelsLoading() {
        return this.storeState.userHotelsLoading.isLoading();
    }

    async awaitLoading() {
        await this.storeState.loading.whenLoadingFinished();
    }
}
