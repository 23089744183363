import { inject, injectable } from '@/inversify';

import Stateable from '@/modules/common/interfaces/stateable.interface';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import RatesStore from '@/modules/rates/store/rates.store';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import ANY_ROOM_TYPE from '@/modules/room-types/constants/any-room-type.constant';
import ANY_MEAL_TYPE from '@/modules/meal-types/constants/any-meal-type.constant';
import ScanDisabledProviders from '@/modules/common/modules/rates/constants/scan-disabled-providers.enum';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import type ClusterService from '../cluster/cluster.service';
import PRICE_TYPE from '../document-filters/constants/price-type.constant';
import RatesSettingsModel from './models/rates-settings.model';
import DocumentFiltersModel from '../document-filters/models/document-filters.model';
import RatesCompsetMainModel from '../cluster/models/rates-compset-main.model';
import ClusterCompsetsService, { ClusterCompsetsServiceS } from '../cluster/cluster-compsets.service';
import { SettingsGeneralService } from '../settings/settings-general.service';

export const RatesFiltersServiceS = Symbol.for('RatesFiltersServiceS');
@injectable()
export default class RatesFiltersService implements Stateable {
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.SettingsGeneralService) private settingsGeneralService!: SettingsGeneralService;
    @inject(KEY.ClusterService) private clusterService!: ClusterService;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(ProvidersServiceS) private providersService!: ProvidersService;
    @inject(ClusterCompsetsServiceS) private clusterCompsetsService!: ClusterCompsetsService;

    readonly storeState: RatesStore = this.storeFacade.getState('RatesStore');

    constructor() {
        this.storeFacade.watch(() => this.documentFiltersService.storeState.settings.compsetId, this.resetCompsetProvider.bind(this));
        this.storeFacade.watch(() => this.compsetsService.competitors, () => {
            if (this.compsetsService.competitors) {
                this.setDefaultCompetitors();
            }
        });
    }

    get settings() {
        return this.storeState.settings;
    }

    get priceType() {
        return this.storeState.settings.priceType || this.settingsGeneralService.defaultFilters.price;
    }

    set priceType(value: PRICE_TYPE) {
        this.storeState.settings.priceType = value;
    }

    set providerFromHighLevel(value: string | null) {
        this.storeState.providerFomHighLevel = value;
    }

    get providerFromHighLevel() {
        return this.storeState.providerFomHighLevel;
    }

    get currentProvider() {
        return this.storeState.settings.provider;
    }

    get currentNumberOfGuests() {
        return this.storeState.settings.numberOfGuests;
    }

    get isProviderAll() {
        return this.currentProvider === ScanDisabledProviders.ALL;
    }

    get isProviderCheapest() {
        return this.currentProvider === ScanDisabledProviders.CHEAPEST;
    }

    get isScanDisabledProvider() {
        const { provider } = this.storeState.settings;
        return this.providersService.isDisabledProvider(provider);
    }

    resetFilters() {
        this.resetCompsetProvider();
        this.resetMealType();
        this.resetRoomType();
        this.resetPriceType();
        this.resetNumberOfGuests();
        this.setDefaultCompetitors();
    }

    async resetCompsetProvider() {
        if (!this.compsetsService || this.compsetsService.currentCompset === null) {
            return;
        }

        const { rateProviders } = this.compsetsService.currentCompset;
        this.setDefaultCompetitors();

        if (this.providerFromHighLevel) {
            this.saveProvider(this.providerFromHighLevel);
            return;
        }

        if (this.storeState.settings.provider === null
            || !rateProviders.includes(this.storeState.settings.provider)) {
            this.saveProvider(
                (rateProviders && rateProviders.length)
                    ? rateProviders[0]
                    : this.storeState.settings.provider,
            );
        }
    }

    resetMealType() {
        const { mealTypes } = this.mealTypesService;

        const defaultMealType = this.mealTypesService.getMealType(null)!;

        const mealTypeId = mealTypes && mealTypes.length ? defaultMealType!.id : null;
        if (mealTypeId !== null) {
            this.storeState.settings.mealTypeId = mealTypeId;
        } else {
            this.storeState.settings.mealTypeId = ANY_MEAL_TYPE.id;
        }
    }

    resetRoomType() {
        const { rooms } = this.roomTypesService;

        const roomTypeId = rooms && rooms.length ? rooms[0].id : null;
        if (roomTypeId !== null) {
            this.storeState.settings.roomTypeId = roomTypeId;
        } else {
            this.storeState.settings.roomTypeId = ANY_ROOM_TYPE.id;
        }
    }

    setDefaultCompetitors() {
        const { competitors } = this.compsetsService;

        if (!competitors) {
            return null;
        }

        this.documentFiltersService.competitors = competitors;
        return true;
    }

    setClusterCompetitors(hotelId: number) {
        const mainCompsetData = this.clusterService.getMainCompsetData<RatesCompsetMainModel>(hotelId);
        if (!mainCompsetData) return;
        this.documentFiltersService.competitors = this.clusterCompsetsService.getCompetitors(mainCompsetData.id) || [];
    }

    setPriceType(priceType: PRICE_TYPE) {
        this.storeState.settings = { ...this.storeState.settings, priceType };
    }

    setMealType(mealTypeId: number) {
        this.storeState.settings = { ...this.storeState.settings, mealTypeId };
    }

    resetPriceType() {
        const priceType = this.settingsGeneralService.defaultFilters.price;
        this.priceType = priceType;
    }

    resetNumberOfGuests() {
        this.storeState.settings.numberOfGuests = this.settingsGeneralService.defaultFilters.numberOfGuests;
    }

    async saveProvider(provider: string | null): Promise<void> {
        this.storeState.settings.provider = provider;
        this.providerFromHighLevel = null;
        this.updateFieldsState();
    }

    async saveNumberOfGuests(numberOfGuests: number) {
        this.storeState.settings.numberOfGuests = numberOfGuests;
    }

    private refreshLosFilter() {
        const isCurrentLosAvailable = this.documentFiltersService.losItems
            .find(i => i.value === this.documentFiltersService.los);

        if (isCurrentLosAvailable) return;

        const { losItems } = this.documentFiltersService;
        const firstEnabledLos = losItems[0]?.value;
        const defaultLos = this.settingsGeneralService.defaultFilters.los || firstEnabledLos;
        const isLosExists = losItems.find(los => los.value === defaultLos);

        if (isLosExists) {
            this.documentFiltersService.saveLos(defaultLos);
        }

        this.documentFiltersService.saveLos(firstEnabledLos);
    }

    private refreshPosFilter(provider: string, compsetId: string) {
        const currentCompset = this.compsetsService.getCompset(compsetId)!;
        if (!currentCompset) return;

        const selectedPos = this.documentFiltersService.settings.pos;
        const providerPos = currentCompset.providersPos?.find(item => item.name === provider);
        if (!providerPos) return;

        const isSelectedPosAvailable = providerPos?.pos.includes(selectedPos as string);
        const isMainPosAvailable = providerPos?.pos.includes(currentCompset.mainPos);

        if (isSelectedPosAvailable) return;

        if (!isMainPosAvailable) {
            this.documentFiltersService.savePos(providerPos.pos[0]);
            return;
        }

        this.documentFiltersService.savePos(currentCompset.mainPos);
    }

    private updateFieldsState() {
        const { provider } = this.storeState.settings;
        const { compsetId } = this.documentFiltersService.storeState.settings;
        if (!provider || !compsetId) return;

        const isLimitedProvider = ['google', 'all'].includes(provider);
        if (!isLimitedProvider) return;

        this.refreshLosFilter();
        this.refreshPosFilter(provider, compsetId);
    }

    isFilterDisabled(filter: keyof (RatesSettingsModel & DocumentFiltersModel)): boolean {
        return this.storeState.disabledFilters.includes(filter);
    }
}
