import { plainToClass } from 'class-transformer';
import { injectable } from '@/inversify.injectable';
import { inject } from '@/inversify.inject';

import ApiService, { ApiServiceS } from '../common/services/api.service';
import RVSWeightSettingsModel from './models/rvs-weights-settings.model';
import { SettingsModel } from './models/settings.model';
import SpecialDateModel from '../user/models/special-date.model';
import UserHotelAlertsModel, { HotelAlertsAndNotifications } from '../user/models/user-hotel-alerts.model';
import { DefaultFilters } from '../user/types';
import { DefaultFiltersPayloadModel } from './models/default-filters-payload.model';
import { SubscriptionsSettingsModel } from './models/subscriptions-settings.model';

@injectable()
export default class SettingsApiService {
    @inject(ApiServiceS) private apiService!: ApiService;

    // NOTE: RVS Weight settings
    async getWeightsSettings(hotelId: number, chainId: string) {
        const { data } = await this.apiService.get(`hotel-mappings/${hotelId}/${chainId}`);

        return plainToClass(RVSWeightSettingsModel, data, { excludeExtraneousValues: true });
    }

    updateWeightsSettings(hotelId: number | number[], chainId: string, newWeights: Record<string, number>) {
        if (Array.isArray(hotelId)) {
            return this.apiService.put('hotel-mappings/batch-update', {
                fornova_ids: hotelId,
                chain_id: chainId,
                rvs_weights: newWeights,
            });
        }

        return this.apiService.put(`hotel-mappings/${hotelId}/${chainId}`, {
            rvs_weights: newWeights,
        });
    }

    async updateDisplayCurrency(currency: string | null) {
        const settings = { currency };
        const { data } = await this.apiService.post('users/settings', { settings });

        return data;
    }

    updateSettings(settings: Partial<SettingsModel>) {
        return this.apiService.post('users/settings', { settings });
    }

    async addSpecialDate(specialDate: SpecialDateModel) {
        const { data } = await this.apiService
            .post('users/settings/intraday-special-dates', {
                date_name: specialDate.name,
                start_date: specialDate.start,
                end_date: specialDate.end,
            });

        return plainToClass(SpecialDateModel, data.settings.intraday_special_dates as any[], { excludeExtraneousValues: true });
    }

    async deleteSpecialDate(specialDateId: string) {
        await this.apiService
            .delete(`users/settings/intraday-special-dates/${specialDateId}`);
    }

    async updateSpecialDate(specialDate: SpecialDateModel) {
        const { data } = await this.apiService
            .put(`users/settings/intraday-special-dates/${specialDate.id}`, {
                date_name: specialDate.name,
                start_date: specialDate.start,
                end_date: specialDate.end,
            });

        return plainToClass(SpecialDateModel, data.settings.intraday_special_dates as any[]);
    }

    async getHotelSettings(fornovaId: number) {
        const { data } = await this.apiService.get(`hotels/alerts-and-notifications-settings/${fornovaId}`);
        return plainToClass(UserHotelAlertsModel, <UserHotelAlertsModel> data, { excludeExtraneousValues: true });
    }

    updateHotelSettings(settings: HotelAlertsAndNotifications) {
        const fornovaId = Number(Object.keys(settings)[0]);
        const body = {
            fornovaId,
            alertsAndNotifications: {
                ...settings[fornovaId],
            },
        };
        return this.apiService.put('hotels/update-alert-setting', body);
    }

    updateSubscriptions(subs: SubscriptionsSettingsModel) {
        return this.apiService.post('users/settings', {
            settings: {
                subscribedListForMail: {
                    'html-report': subs.htmlReports,
                    'scheduled-excel': subs.scheduledReports,
                    'ondemand-report': subs.ondemandReports,
                },
            },
        });
    }

    updateDefaultFilters(filters: DefaultFilters) {
        const body = plainToClass(DefaultFiltersPayloadModel, filters, { excludeExtraneousValues: true }).cleanup();
        return this.apiService.post('users/save-user-default-filters', body);
    }
}
