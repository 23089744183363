import { inject, injectable } from '@/inversify';
import VueRouter from 'vue-router';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { routerData } from '@/router/routerData';
import { KEY } from '@/inversify.keys';
import CompsetsService, { CompsetsServiceS } from '../compsets/compsets.service';
import HomeFiltersService, { HomeFiltersServiceS } from '../home/home-filters.service';
import AuthService, { AuthServiceS } from '../auth/auth.service';
import { USER_LEVELS } from './constants';
import PAGES from '../common/constants/pages.constant';
import type RatesService from '../rates/rates.service';
import type RatesFiltersService from '../rates/rates-filters.service';
import type MarketsService from '../markets/markets.service';
import type RankingService from '../ranking/ranking.service';
import CompsetModel from '../compsets/models/compset.model';
import type DiLiteAllChannelsService from '../di-lite/di-lite-all-channels.service';
import DeepCompsetAnalysisClusterService, { DeepCompsetAnalysisClusterServiceS } from '../cluster/cluster-deep-analysis.service';
import type DeepCompsetAnalysisService from '../deep-analysis/deep-analysis.service';
import type PromotionsService from '../promotions/promotions.service';
import type DocumentFiltersService from '../document-filters/document-filters.service';
import type ClusterService from '../cluster/cluster.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '../rates/rates-analysis-filters.service';
import type InsightFiltersService from '../document-filters/insight-filters.service';
import UserNavigationService, { UserNavigationServiceS } from './user-navigation.service';

interface UserViewServicePublicInterface {
    /**
     * Method to switch level to hotel or change currently selected hotel.
     * @param hotelId id of the hotel to move.
     * @param compset compset parameter should be provided if moved from cluster page and it is needed to keep filter from cluster on hotel page.
     */
    goToHotelPage: (
        hotelId: number,
        compset?: CompsetModel,
    ) => Promise<void>;

    /**
     * Method to switch level to cluster.
     * @param clusterId should be enabled, when chain level will be released
     */
    goToClusterPage: (/* clusterId: string */) => Promise<void>;
}

export const UserViewServiceS = Symbol.for('UserViewServiceS');
@injectable()
export default class UserViewService implements UserViewServicePublicInterface {
    @inject(UserServiceS) private userService!: UserService;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(HomeFiltersServiceS) private homeFiltersService!: HomeFiltersService;
    @inject(AuthServiceS) private authService!: AuthService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.RatesService) private ratesService!: RatesService;
    @inject(KEY.RatesFiltersService) private ratesFiltersService!: RatesFiltersService;
    @inject(KEY.MarketsService) private marketsService!: MarketsService;
    @inject(KEY.RankingService) private rankingService!: RankingService;
    @inject(KEY.DiLiteAllChannelsService) private diLiteAllChannelsService!: DiLiteAllChannelsService;
    @inject(DeepCompsetAnalysisClusterServiceS) private dcaClusterService!: DeepCompsetAnalysisClusterService;
    @inject(KEY.DeepCompsetAnalysisService) private dcaService!: DeepCompsetAnalysisService;
    @inject(KEY.PromotionsService) private promotionsService!: PromotionsService;
    @inject(KEY.ClusterService) private clusterService!: ClusterService;
    @inject(RatesAnalysisFiltersServiceS) private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;
    @inject(KEY.InsightFiltersService) private insightFiltersService!: InsightFiltersService;
    @inject(UserNavigationServiceS) private userNavigationService!: UserNavigationService;

    private router = routerData.router as VueRouter;

    /**
     * Method to switch level to hotel or change currently selected hotel.
     * @param hotelId id of the hotel to move.
     * @param options compsetId parameter should be provided if moved from cluster page and it is needed to keep filter from cluster on hotel page.
     * query parameter to keep any query from the path.
     */
    async goToHotelPage(
        hotelId: number,
        compset?: CompsetModel,
    ) {
        if (this.userService.viewAs === USER_LEVELS.HOTEL && this.userService.currentHotelId === hotelId) {
            return;
        }

        await this.userService.setViewAs(USER_LEVELS.HOTEL, hotelId);
        await this.resetHotelData();

        const [_, page] = this.router.currentRoute.name!.split('.');

        // NOTE: Set compset related filters if compsetId is provided
        //       compsetId is provided only if moved from cluster page
        if (compset) {
            if (compset.id) {
                this.documentFiltersService.compsetIdFromHighLevel = compset.id;
                this.documentFiltersService.compsetId = compset.id;
                this.documentFiltersService.savePos(compset.mainPos || compset.pos[0]);
            }

            this.setHotelPageFilters(page as PAGES);
        }

        this.authService.socketHandshake();

        await this.router.push({
            name: `hotel.${page}`,
            params: {
                ...this.router.currentRoute.params,
                hotelId: String(hotelId),
            },
        });
    }

    /**
     * Method to switch level to cluster.
     * @param clusterId should be enabled, when chain level will be released
     */
    async goToClusterPage(/* clusterId: string */) {
        if (this.userService.isViewAsChain) {
            return;
        }

        // [TODO] add cluster id check when chain level will be enabled
        if (this.userService.isViewAsCluster) {
            return;
        }

        const { level } = this.userService.user;
        await this.userService.setViewAs(level);

        // No cluster pages
        if (this.router.currentRoute.name === 'hotel.home') {
            await this.router.push({ name: 'cluster.rates' });
        } else {
            // [TODO] as cluster and chain are the same for now. When chain will be enabled,
            // replace this.userService.user.level with `cluster`, and add separate method for chain
            const [_, page] = this.router.currentRoute.name!.split('.');
            let targetRoute = this.router.match({ name: `${this.userService.user.level}.${page}` });

            // NOTE: In case route points to the root - redirect to the first enabled page
            if (targetRoute.path === '/') {
                targetRoute = this.router.match(this.userNavigationService.firstEnabledRoute);
            }

            await this.router.push(targetRoute.fullPath);
        }
    }

    private setHotelPageFilters(page: PAGES) {
        // Set filters from cluster to hotel
        switch (page) {
            case PAGES.RATES:
                this.ratesFiltersService.saveProvider(this.clusterService.currentRatesProvider);
                this.ratesFiltersService.providerFromHighLevel = this.clusterService.currentRatesProvider;
                this.ratesFiltersService.settings.roomTypeId = this.clusterService.ratesSettings.roomTypeId;
                this.ratesFiltersService.settings.priceType = this.clusterService.ratesSettings.priceType!;
                this.ratesFiltersService.settings.mealTypeId = this.clusterService.ratesSettings.mealTypeId;
                this.ratesFiltersService.settings.numberOfGuests = this.clusterService.ratesSettings.numberOfGuests;
                if (!this.ratesAnalysisFiltersService.comparisonValues.length) {
                    this.ratesAnalysisFiltersService.resetComparisonFilters();
                }
                break;
            case PAGES.MARKETS:
                // [TODO] set markets table provider from providerFromHighLevel
                break;
            case PAGES.RANKING:
                break;
            case PAGES.DILITE:
                this.diLiteAllChannelsService.settings.priceType = this.clusterService.ratesSettings.priceType!;
                this.diLiteAllChannelsService.settings.roomTypeId = this.clusterService.ratesSettings.roomTypeId;
                this.diLiteAllChannelsService.settings.mealTypeId = this.clusterService.ratesSettings.mealTypeId;
                break;
            case PAGES.DEEP_ANALYSIS: {
                this.dcaService.showBy = this.dcaClusterService.showBy;
                this.dcaService.provider = this.dcaClusterService.provider;
                this.dcaService.compareTo = this.dcaClusterService.compareTo;
                break;
            }
            case PAGES.PROMOTION_DETECTION:
                break;
            case PAGES.INSIGHTS:
                this.insightFiltersService.updateSettings({
                    insightTypes: this.insightFiltersService.options.insightTypes,
                    providers: [this.clusterService.currentRatesProvider],
                });
                break;
            default:
                break;
        }
    }

    private async resetHotelData() {
        // Remove documents on level/hotel change
        // [TODO] check is it needed
        this.ratesService.saveDocument(null);
        this.ratesFiltersService.resetFilters();
        this.marketsService.storeState.providersMarketsDocuments = {};
        this.rankingService.storeState.document = null;
        this.promotionsService.resetDocument();
        // Remove hotel compsets data
        this.compsetsService.storeState.compsets = null;
        this.documentFiltersService.compsetId = null;
        this.documentFiltersService.competitors = [];

        // Reset hotel insights filters
        this.insightFiltersService.updateSettings({
            insightTypes: this.insightFiltersService.options.insightTypes,
            providers: null,
        });
        await this.homeFiltersService.resetRatesProvider();
    }
}
